<template>
  <div class="user-list-row">
    <user-card :user="source"></user-card>
  </div>
</template>

<script>
export default {
  props: [
    'index',
    'source'
  ]
}
</script>